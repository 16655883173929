import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { colors } from '@bblabs/styles';
import { WarningFill } from '@bblabs/svgs';

import Store from './Store';
import ResendLink from './ResendLink';
import BannerWrapper from './BannerWrapper';
import PrimaryBannerText from './PrimaryBannerText';

const ErrorBanner = observer(({ className }) => {
  if (!Store.showVerificationBanner) {
    return null;
  }

  return (
    <BannerWrapper
      className={className}
      fill={colors.fog}
      dismissable
      onClose={Store.dismissVerification}
    >
      <PrimaryBannerText>
        <div className="icon">
          <WarningFill size="5.5" fill="recordRed" />
        </div>
        {Store.verificationErrorText} <em>{Store.verificationEmailAddress}</em>
      </PrimaryBannerText>
      <ResendLink />
    </BannerWrapper>
  );
});

ErrorBanner.propTypes = {
  className: PropTypes.string,
};

ErrorBanner.defaultProps = {
  className: '',
};

const styledErrorBanner = styled(ErrorBanner)``;

export default styledErrorBanner;
