import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './components/AppWrapper';
import App from './components/App';
import { name } from '../package.json';

const load = () => {
  ReactDOM.render(
    <AppWrapper>
      <App />
    </AppWrapper>,
    document.getElementById(name),
  );
};

window.addEventListener('load', load);

if (document.readyState === 'complete') {
  load();
}
