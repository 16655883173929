import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { colors } from '@bblabs/styles';
import { ClickableElement } from '@bblabs/atoms';

import Store from './Store';

const ResendLink = observer(({ className }) => {
  if (!Store.verificationErrorIsRetryable) {
    return null;
  }

  return (
    <ClickableElement className={className} onClick={Store.resendVerificationEmail}>
      <div>Resend Verification Email</div>
    </ClickableElement>
  );
});

ResendLink.propTypes = {
  className: PropTypes.string,
};

ResendLink.defaultProps = {
  className: '',
};

const styledResendLink = styled(ResendLink)`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${colors.taosSky};
  text-decoration: underline;
  margin: 0.25rem 0 0 0;
  min-width: 8.75rem;
`;
// margin: auto 0;
export default styledResendLink;
