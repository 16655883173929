import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EnvironmentHelper } from '@bblabs/classes';
import { colors, medium } from '@bblabs/styles';
import { Button } from '@bblabs/atoms';
import styled from '@emotion/styled';

const getToken = () => {
  let accessToken = null;
  const cookie = `; ${document.cookie}`;
  const parts = cookie.split('; BBV2APITOKEN=');
  if (parts.length === 2) {
    accessToken = parts.pop().split(';').shift();
  }

  return accessToken;
};

const setTokenCookie = token => {
  const newCookie = `BBV2APITOKEN=${token};`;
  document.cookie = newCookie;
};

const clearTokenCookie = () => {
  document.cookie = 'BBV2APITOKEN=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

const getEnvironment = () => {
  const url = window.location.href;
  if (url.indexOf('local.dev.bombbomb.io') > -1) {
    return 'LOCAL-DIRECT';
  }

  if (url.indexOf('dev.bombbomb.io') > -1) {
    return 'DEV-DIRECT';
  }

  let environment = 'PROD';
  const match = url.match(/\/\/([^.]+)[.]/);
  if (match && match[1]) {
    const subdomain = match[1].toUpperCase();
    if (subdomain.match(/^DEV|LOCAL$/)) {
      environment = subdomain;
    }
  }
  return environment;
};

const AppWrapper = ({ className, children }) => {
  const token = getToken();
  const [desiredToken, setDesiredToken] = useState('');

  const environment = getEnvironment();
  EnvironmentHelper.setEnvironment(environment.replace('-DIRECT', '').toLowerCase());

  const setToken = () => {
    setTokenCookie(desiredToken);
    setDesiredToken('');
  };

  const clearToken = () => {
    clearTokenCookie();
    window.location.reload();
  };

  const desiredTokenChange = event => {
    setDesiredToken(event.target.value);
  };

  const renderTokenControls = () => {
    if (environment.indexOf('-DIRECT') === -1) {
      return null;
    }

    const isTokenPresent = token !== null;

    return (
      <div className="tokenToolbar">
        <input
          type="text"
          placeholder="V2 OAuth Token"
          value={desiredToken}
          onChange={desiredTokenChange}
        />
        <span>{isTokenPresent}</span>
        <Button
          className="button"
          label="set"
          size="xsmall"
          rectangle
          color="taos-sky"
          onClick={setToken}
        />
        <Button
          className="button"
          label="clear"
          size="xsmall"
          rectangle
          color="tutu"
          onClick={clearToken}
          disabled={!isTokenPresent}
        />
      </div>
    );
  };

  const newProperties = {
    token,
    environment,
  };
  const enhancedChildren = React.cloneElement(children, newProperties);

  return (
    <div className={className}>
      {renderTokenControls()}
      {enhancedChildren}
    </div>
  );
};

AppWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default styled(AppWrapper)`
  .tokenToolbar {
    ${medium()}
    background: ${colors.battleship};
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 0.125rem ${colors.boxShadow};
    margin-bottom: 0.125rem;

    input {
      flex: 1;
      max-width: 10rem;
    }

    .button {
      margin: 0 0 0 0.25rem;
    }
  }
`;
