import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import Store from './Store';
import UnverifiedBanner from './UnverifiedBanner';

const UnverifiedAddresses = observer(({ className }) => {
  if (!Store.unverifiedAddresses) {
    return null;
  }

  const getBanner = address => {
    return (
      <UnverifiedBanner key={address.id} emailAddress={address.email_address} id={address.id} />
    );
  };

  return (
    <div className={className}>{Store.unverifiedAddresses.map(address => getBanner(address))}</div>
  );
});

UnverifiedAddresses.propTypes = {
  className: PropTypes.string,
};

UnverifiedAddresses.defaultProps = {
  className: '',
};

const styledUnverifiedAddresses = styled(UnverifiedAddresses)``;

export default styledUnverifiedAddresses;
