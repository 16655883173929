import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { colors } from '@bblabs/styles';

import Store from './Store';
import BannerWrapper from './BannerWrapper';
import PrimaryBannerText from './PrimaryBannerText';

const UnverifiedBanner = observer(({ className, emailAddress, id }) => {
  const dismissUnverified = () => {
    Store.dismissUnverified(id);
  };

  return (
    <BannerWrapper className={className} fill={colors.fog} onClose={dismissUnverified}>
      <PrimaryBannerText className="allow-wrap">
        Email Verification sent to <em className="email-address">{emailAddress}. </em> Check status
        in <a href={Store.getProfileLink()}>profile settings</a>.
      </PrimaryBannerText>
    </BannerWrapper>
  );
});

UnverifiedBanner.propTypes = {
  className: PropTypes.string,
  emailAddress: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

UnverifiedBanner.defaultProps = {
  className: '',
};

const styledUnverifiedBanner = styled(UnverifiedBanner)`
  em {
    margin: 0 0.25rem;
    color: ${colors.taosSky};
  }

  a,
  a:visited {
    color: #707070;
  }

  .allow-wrap {
    white-space: normal;
  }
`;

export default styledUnverifiedBanner;
