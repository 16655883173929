import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { colors } from '@bblabs/styles';

import Store from './Store';
import BannerWrapper from './BannerWrapper';
import PrimaryBannerText from './PrimaryBannerText';

const SendingBanner = observer(({ className }) => {
  if (!Store.showSendingBanner) {
    return null;
  }

  return (
    <BannerWrapper
      className={className}
      dismissable
      dismissIconFill="white"
      fill={colors.taosSky}
      onClose={Store.dismissVerification}
    >
      <PrimaryBannerText>
        Sending Email Verification to <em>{Store.verificationEmailAddress}</em>. Check your inbox.
      </PrimaryBannerText>
    </BannerWrapper>
  );
});

SendingBanner.propTypes = {
  className: PropTypes.string,
};

SendingBanner.defaultProps = {
  className: '',
};

const styledSendingBanner = styled(SendingBanner)`
  color: ${colors.white};

  .email-address {
    color: ${colors.taosSkyLight};
  }
`;

export default styledSendingBanner;
