import queryString from 'query-string';
import { EnvironmentHelper } from '@bblabs/classes';

const { computed, observable } = require('mobx');

class Store {
  @observable token = null;

  @observable unverifiedAddresses;

  @observable verificationClientId;

  @observable verificationCode;

  @observable verificationEmailAddress;

  @observable verificationErrorIsRetryable = false;

  @observable verificationErrorText;

  @observable verificationId;

  @observable verificationSending = false;

  @observable verificationSent = false;

  @observable verificationSuccess = false;

  @computed get showVerificationBanner() {
    return this.verificationErrorText && !this.verificationSending && !this.verificationSent;
  }

  @computed get showSendingBanner() {
    return this.verificationSending;
  }

  initialize = (environment, token) => {
    this.environment = environment;
    this.token = token;
    this.verificationCode = queryString.parse(window.location.search).emailVerificationCode;

    this.getSendingAddresses();
    if (this.verificationCode) {
      this.verifyCode();
    }
  };

  dismissUnverified = id => {
    const addressBeingDismissed = this.unverifiedAddresses.find(address => address.id === id);
    this.unverifiedAddresses.remove(addressBeingDismissed);
  };

  dismissVerification = () => {
    const query = queryString.parse(window.location.search);
    delete query.emailVerificationCode;
    const originAndPath = window.location.origin + window.location.pathname;
    const newUrl = queryString.stringifyUrl({ url: originAndPath, query });
    window.history.replaceState({}, '', newUrl);

    this.verificationEmailAddress = undefined;
    this.verificationErrorText = undefined;
    this.verificationErrorIsRetryable = undefined;
    this.verificationSending = false;
    this.verificationSuccess = false;
  };

  ensureInitialized = () => {
    if (!this.environment || !this.token) {
      /* eslint no-console: ["warn", { allow: ["error"] }] */
      console.error('Store cannot make call until it has been initialized.');
    }
  };

  getBaseUrl = () => {
    return `${EnvironmentHelper.getApiUrl()}clients/sending_addresses`;
  };

  getHeaders = () => {
    return new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });
  };

  getSendingAddresses = async () => {
    this.ensureInitialized();
    const url = `${this.getBaseUrl()}`;
    const options = {
      method: 'GET',
      headers: this.getHeaders(),
    };

    try {
      const result = await fetch(url, options);
      const list = await result.json();
      this.unverifiedAddresses = this.filterUnverifiedAddresses(list);
    } catch (error) {
      console.error(error);
      this.unverifiedAddresses = [];
    }
  };

  filterUnverifiedAddresses = list => {
    if (!list || !(list.length > 0)) {
      return [];
    }

    if (this.verificationEmailAddress) {
      const addressUnderVerification = list.find(
        address => address.email_address === this.verificationEmailAddress,
      );
      addressUnderVerification.verified = '1';
    }

    return list.filter(address => address.verified !== '1');
  };

  getProfileLink = () => {
    const origin = EnvironmentHelper.getAppUrl();
    const pathAndQuery = '/app/index.php?module=users&page=edit&current=1&sendingAddress=1';
    return origin + pathAndQuery;
  };

  resendVerificationEmail = async () => {
    this.ensureInitialized();
    const url = `${this.getBaseUrl()}/client/resendEmail`;
    const options = {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({
        emailAddress: this.verificationEmailAddress,
        verificationId: this.verificationId,
      }),
    };
    this.verificationSending = true;
    await fetch(url, options);
  };

  verifyCode = async () => {
    this.ensureInitialized();
    const url = `${this.getBaseUrl()}/verify/${this.verificationCode}`;
    const options = {
      method: 'POST',
      headers: this.getHeaders(),
    };

    let result;
    try {
      result = await fetch(url, options);
    } catch (error) {
      /* eslint no-console: ["warn", { allow: ["error"] }] */
      console.error('Unexpected error during verification', error);
    }

    switch (result?.status) {
      case 200:
        this.handleVerificationOk(result);
        return;
      case 404: // No verification code matched
        this.handleVerificationNotFound();
        return;
      case 410: // Verification code expired
        this.handleVerificationExpired(result);
        return;
      default:
        break;
    }

    this.handleVerificationUnknownError(result);
  };

  handleVerificationOk = async result => {
    this.verificationEmailAddress = await result.json();
    this.verificationSuccess = true;
    this.unverifiedAddresses = this.filterUnverifiedAddresses(this.unverifiedAddresses);
  };

  handleVerificationNotFound = async () => {
    this.verificationErrorText =
      'The email address you are trying to verify no longer exists in our system';
    this.verificationErrorIsRetryable = false;
  };

  handleVerificationExpired = async result => {
    const retryInfo = await result.json();
    this.verificationErrorText = `Verification code used has expired.`;
    this.verificationEmailAddress = retryInfo.emailAddress;
    this.verificationId = retryInfo.verificationId;
    this.verificationClientId = retryInfo.clientId;
    this.verificationErrorIsRetryable = true;
    this.verificationSuccess = false;
  };

  handleVerificationUnknownError = async result => {
    this.verificationErrorText = 'There was an issue validating your email';
    try {
      const address = await result.json();
      this.verificationEmailAddress = address;
      this.verificationErrorIsRetryable = true;
    } catch (error) {
      this.verificationErrorIsRetryable = false;
    }
  };
}

const singleton = new Store();
export default singleton;
