import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { medium } from '@bblabs/styles';
import { Close } from '@bblabs/svgs';
import { ClickableElement } from '@bblabs/atoms';

const BannerWrapper = observer(({ className, children, dismissable, dismissIconFill, onClose }) => {
  return (
    <div className={className}>
      <div className="banner-text">{children}</div>
      {dismissable && (
        <ClickableElement className="dismiss-button" onClick={onClose}>
          <Close size="4" fill={dismissIconFill} />
        </ClickableElement>
      )}
    </div>
  );
});

BannerWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  dismissable: PropTypes.bool,
  dismissIconFill: PropTypes.string,
  fill: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

BannerWrapper.defaultProps = {
  className: '',
  dismissable: false,
  dismissIconFill: 'boulder',
  onClose: () => {},
};

const styledBannerWrapper = styled(BannerWrapper)`
  ${medium()}
  color: #707070;
  font-size: 0.875rem;
  line-height: 1rem;
  background: ${props => props.fill};
  text-align: center;
  padding: 0.875rem;
  display: flex;
  margin-bottom: 0.125rem;

  .banner-text {
    right: -0.875rem;
    margin-right: 1.75rem;
    position: relative;
    width: 100%;
    max-width: calc(100% - 1.75rem);
    overflow: hidden;

    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dismiss-button {
    width: 0.6875rem;
    height: 100%;
    z-index: 2;
    margin: auto;
  }

  @media only screen and (min-width: 768px) {
    .banner-text {
      display: flex;
      justify-content: center;
    }
  }
`;

export default styledBannerWrapper;
