import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const PrimaryBannerText = observer(({ className, children }) => (
  <div className={className}>{children}</div>
));

PrimaryBannerText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PrimaryBannerText.defaultProps = {
  className: '',
};

const styledPrimaryBannerText = styled(PrimaryBannerText)`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto 2rem;

  em {
    color: #434546;
    font-style: normal;
  }

  .icon {
    margin: 0.375rem 0.5rem;

    svg {
      top: 3px;
      position: relative;
    }
  }

  @media only screen and (min-width: 768px) {
    white-space: nowrap;

    .icon {
      display: inline;
    }
  }
`;

export default styledPrimaryBannerText;
