import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { colors } from '@bblabs/styles';

import { SuccessFill } from '@bblabs/svgs';
import Store from './Store';
import BannerWrapper from './BannerWrapper';
import PrimaryBannerText from './PrimaryBannerText';

const SuccessBanner = observer(({ className }) => {
  if (!Store.verificationSuccess) {
    return null;
  }

  return (
    <BannerWrapper
      className={className}
      dismissable
      fill={colors.fog}
      onClose={Store.dismissVerification}
    >
      <PrimaryBannerText>
        <div className="icon">
          <SuccessFill size="5.5" fill="kermit" />
        </div>
        Your email address <em>{Store.verificationEmailAddress}</em> has been verified.
      </PrimaryBannerText>
    </BannerWrapper>
  );
});

SuccessBanner.propTypes = {
  className: PropTypes.string,
};

SuccessBanner.defaultProps = {
  className: '',
};

const styledSuccessBanner = styled(SuccessBanner)``;

export default styledSuccessBanner;
