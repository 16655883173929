import React from 'react';
import PropTypes from 'prop-types';
import Store from './Store';
import ErrorBanner from './ErrorBanner';
import SendingBanner from './SendingBanner';
import SuccessBanner from './SuccessBanner';
import UnverifiedAddresses from './UnverifiedAddresses';

const App = ({ environment, token, className }) => {
  if (!environment || !token) {
    return null;
  }

  Store.initialize(environment, token);

  // Each banner is responsible for rendering itself based on values in Store
  return (
    <div className={className}>
      <UnverifiedAddresses />

      <ErrorBanner />
      <SendingBanner />
      <SuccessBanner />
    </div>
  );
};

App.propTypes = {
  token: PropTypes.string,
  environment: PropTypes.string,
  className: PropTypes.string,
};

App.defaultProps = {
  token: null,
  environment: null,
  className: '',
};

export default App;
